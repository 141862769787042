a,
.link,
.btn.btn-link {
  color: var(--py-color-secondary);

  &:hover {
    color: var(--py-color-secondary-dark);
  }

  &:disabled {
    color: var(--py-color-grey-dark);
  }

  &:visited {
    color: var(--py-color-secondary-darker);
  }

  &:focus-visible {
    box-shadow: none;

    &:link,
    &:visited {
      outline: 1px auto var(--py-color-secondary-dark--60);
      outline-offset: 3px;
    }
  }
}

.link {
  display: inline-flex;
  align-items: center;
}
